import {initializeApp} from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCoaMz-LO05GepHmqM2ZWqHSQ4MGvxXpEM",
  authDomain: "olive-82a1f.firebaseapp.com",
  databaseURL: "https://olive-82a1f.firebaseio.com",
  projectId: "olive-82a1f",
  storageBucket: "olive-82a1f.appspot.com",
  messagingSenderId: "465628404",
  appId: "1:465628404:web:759b35647af3e1b4bf82a1",
  measurementId: "G-CQFDPLNCNY"
};


const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

//토큰값 얻기
getToken(messaging, {
  vapidKey:
    "BLM-XAifbv0gAxV8TfOxg0Wrf9hJoO_kwChBYxxPiAArMm2CeBXqdxSbkFLDI0StTqzscrY_YinRSyV6Zvau7OM",
}).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    console.log(currentToken);
    // ...
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});



//포그라운드 메시지 수신
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});











export {app};